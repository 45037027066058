import { createSlice } from '@reduxjs/toolkit'

const initialState = { showSideBar:  true, processState: {}, tableData: [], syncedData: {}, currentStep:{}, sessionExist: null, errorModalVisible:false, isPausedCompleted: null, pausedData: {} }

const AppSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSideBarState(state, action) {
      state.showSideBar = action.payload
      return state
    },

    setProcessState(state, action){
      const {processId, deviceId, ...rest} = action.payload;
      state.processState = {...state.processState, [`${processId} ${deviceId}`]: {...rest} }
      return state
    },

    setTableData(state, action){
      state.tableData = action.payload
      return state
    },

    setSyncedData(state, action){
      state.syncedData = action.payload
      return state
    },

    setCurrentStep(state, action){
      const {processId, deviceId, step} = action.payload;
      state.currentStep = {...state.currentStep, [`${processId} ${deviceId}`]: step }
      return state
    },

    setSessionExist(state, action){
      state.sessionExist = action.payload
      return state
    },

    setErrorModalVisible(state, action){
      state.errorModalVisible = action.payload
      return state
    },
    
    setIsPaused(state, action){
      state.isPausedCompleted = action.payload
      return state
    },

    setPausedData(state, action){
      state.pausedData = action.payload
      return state
    },
    resetAppData(state,_) {
      state = initialState
      return state
    },
    removeProcessState(state, action){
      let temp:any = {...state.processState}
      let { processId, deviceId} = action.payload
      delete temp[`${processId} ${deviceId}`]
      state.processState = temp
    },
    removeCurrentStep(state, action){
      let temp:any = {...state.currentStep}
      let { processId, deviceId} = action.payload
      delete temp[`${processId} ${deviceId}`]
      state.currentStep = temp
    }
  },
  extraReducers: (builder) => { }
})

export const { setSideBarState, setProcessState, setTableData, setSyncedData, setCurrentStep, setSessionExist, setErrorModalVisible, setIsPaused, setPausedData, resetAppData, removeProcessState, removeCurrentStep } = AppSlice.actions
export default AppSlice.reducer

export const selectCurrentAppState = (state: any) => state.app