import { Layout, Menu } from "antd";
import './style.css';
import { logoutIcon, activeProcess, deviceTestIcon, helpIcon, executeProcessIcon, activities, white_activities } from "../../../assets/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Page } from "../../../routes/config";
import { PageTitle } from "../../../config/enum";

const { Sider } = Layout;
const MenuIcon = (props:any)=>{
  const {icon} = props;  
  return (<img src={icon} alt="icon" width={20} style={{width:32,height: 32,  marginRight: 20}}/>)
}

const UISidebar = (props:any) => {
  const {collapsed, setCollapsed} = props;
  const mql = window.matchMedia("(max-width: 1000px)");

  mql.addEventListener("change", () => {
    if(mql.matches){
      setCollapsed(true);
    }else{
      setCollapsed(false);
    }
  });

  const location = useLocation();
  const currentRoute = location.pathname;
  const navigate = useNavigate();
  const items:any = [
    {
      key: "1",
      icon: <MenuIcon icon={activeProcess}/>,
      label: <Link to={Page.ACTIVE_PROCESS}>{PageTitle.ACTIVE_PROCESS}</Link>,
      route:Page.ACTIVE_PROCESS
    },
    {
      key: "2",
      icon: <MenuIcon icon={deviceTestIcon}/>,
      label: <Link to={Page.DEVICE_TEST}>{PageTitle.DEVICE_TEST}</Link>,
      route:Page.DEVICE_TEST
    },
    {
      key: "3",
      icon: <MenuIcon icon={executeProcessIcon}/>,
      label: <Link to={Page.EXECUTE_PROCESS}>{PageTitle.EXECUTE_PROCESS}</Link>,
      route:Page.EXECUTE_PROCESS
    },
    {
      key: "4",
      icon: <MenuIcon icon={white_activities}/>,
      label: <Link to={Page.PREVIOUS_PROCESS}>{PageTitle.PREVIOUS_PROCESS}</Link>,
    },
    {
      key: "5",
      icon: <MenuIcon icon={helpIcon}/>,
      label: <Link to={Page.HELP}>{PageTitle.HELP}</Link>,
      
    }
  ];

 
  const selectedRoute = items.find((item:any) => item.route === currentRoute);
  const selectedKey:any = selectedRoute?.key;

  const onLogoutClick = () => {
        localStorage.clear();
        navigate(Page.LOGIN);
  }

  return (
    <Sider
    // collapsible
    collapsed={collapsed}
    onCollapse={(value) => setCollapsed(value)}
    style={{
      overflow: 'auto',
      height: '100vh',
      position: 'fixed',
      left: 0,
      top: "60px",
      bottom: 0,
      transition: "0s"
    }}
    className="sideBar"
  >

    <Menu
      theme="dark"
      selectedKeys={[selectedKey]}
      mode="inline"
      items={items}
    />
    <div className="signoutBtnContainer">
    <button title="Click here to Logout" className="signoutBtn" onClick={onLogoutClick}>
      <img src={logoutIcon} alt="icon" className="logout-icon"/> 
      {!collapsed && <span>Logout</span>}
      </button>
    </div>
  </Sider>
);
};

export default UISidebar;
