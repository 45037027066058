export enum ModalTitle {
  FORGOT_PASSWRD = "Forgot Password",
  CHANGE_PASSWRD = "Change Password",
  RESET_PASSWRD = "Reset Password",
  ADD_TENANT = "Add Tenant",
  EDIT_TENANT = "Edit Tenant",
  ADD_USER = "Add User",
  EDIT_USER = "Edit User",
  ADD_DEVICE = "Add Device",
  EDIT_DEVICE = "Edit Device",
  ADD_SITE = "Add Site",
  EDIT_SITE = "Edit Site", 
  ADD_ZONE = "Add Zone",
  EDIT_ZONE = "Edit Zone"
}

export enum ModalType {
  SUCCESS = "success",
  WARN = "warn",
  INVITE = "invite",
}

export enum ModalPrimaryText {
  ACTIVATE = "Are you sure you want to activate %s?",
  DELETE = "Are you sure you want to Stop & Exit Process %s?",
  DEACTIVATE = "Are you sure you want to deactivate %s?",
  INVITE = "Are you sure you want to send invite %s?",
  PROCESS_EXIT = "Are you sure you want to Exit?",
  PAUSE_PROCESS = "Are you sure you want to pause the running process?"
}

export enum ModalButton {
  CANCEL = "CANCEL",
  CONFIRM = "CONFIRM",
  APPLY = "APPLY"
}


export enum PageTitle {
  HOME = "Home",
  ACTIVE_PROCESS= "Active Process",
  DEVICE_TEST = "Device Status",
  EXECUTE_PROCESS = "Execute Process",
  HELP = "Help",
  ABOUT = "About",
  HELP_CENTER = "Help Center",
  ACTIVITY_LOGS = "Activity Logs",
  PREVIOUS_PROCESS = "Audit Logs History"
}

export enum Roles {
  PLATFORM_SUPER_ADMIN = "Platform Super Admin",
  PLATFORM_ADMIN = "Platform Admin",
  TENANT_ADMIN = "Tenant Admin",
  OPERATOR = "Operator",
  SUPERVISOR = "Supervisor",
  PROJECT_MANAGER = "Project Manager"
}
export enum WebsocketKey {
  ACTIVITY_LOGS_TABLE = "activity-log",
  STATE_CHANGE = 'state-change',
  CHECK_REF_STOCK = "check-reference-stock",
  DATA_SYNC_CRS_ERR = 'errorInDataSync',
  Notification_error = "notify_error",
  ERROR_IN_RUNNODE = "error_in_runNode",
  PAUSED_STATE = 'pauseState',
  LastSync='last-sync',
  ReaderIssues='reader_issues'
 }

 export enum dateRanges {
  TODAY = "Today",
  YESTERDAY = "Yesterday",
  THISWEEK = "This Week",
  LASTWEEK = "Last Week",
  THISMONTH = "This Month",
  LASTMONTH = "Last Month",
  LASTSIXMONTH = "Last 6 Month",
}