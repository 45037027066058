import { WebsocketKey } from "../../../config/enum";
import { UITable } from "../../common/table"
import { socket } from "../../../redux/services/webSocket";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectCurrentAppState } from "../../../redux/features/app/appSlice";
import { useEffect, useState } from "react";
import { authStateSelector } from "../../../redux/features/auth/authSlice";
import { ProcessName } from "../../../config/constants";
import moment from "moment";
import { useLocation } from "react-router-dom";

export const ActivityLogsTable = (props: any) => {

  const { logsTableData, tagsReadData, processData } = props
  const {state}:any = useLocation()

  const [stateData, setStateData] = useState(state)
  const dispatch = useDispatch()
  const [tableData, setTableData] = useState<any>([])
  const { stopbutton } = useSelector(selectCurrentAppState)


const dataOfTags = (data:any[]) => {
  let tagsData:any[] = [];
  data?.forEach((el: any) => {
    let x = tagsData?.findIndex((y: any) => y?.upc == el?.upc)
    if(x != -1){
      tagsData[x] = {...tagsData[x],qty:tagsData[x]?.qty + 1}
    }else{
      tagsData.push({...el, qty:1})
    }
  })
  return tagsData;
}

useEffect(()=>{
  setStateData(state)
}, [state])

  useEffect(() => {
    if (tagsReadData?.data?.result) {
      if(processData.processName == ProcessName.CHECK_STOCK){
      setTableData(tagsReadData?.data?.result?.data?.data)
    } else {
      setTableData(dataOfTags(tagsReadData?.data?.result?.data?.data))
    }
   }
    },[tagsReadData?.data, tagsReadData?.isSuccess])
 
    let i =1


      socket.on(`${WebsocketKey.ACTIVITY_LOGS_TABLE}-${stateData?.data?.deviceId}-${stateData?.data?.processId}`, (data: any) => {
        console.log(data, "data from activity log");
        let existingdata = tableData;
        if (processData.processName == ProcessName.CHECK_STOCK) {
          if (data?.state !== "TrackNTrace") {
            console.log(data, "data .......", logsTableData);
            if (
              stateData?.data?.processId == data?.data?.processId &&
              stateData?.data?.deviceId == data?.data?.deviceId
            ) {
              // console.log(`process- ${data?.data?.processId}:ws and ${logsTableData?.data?.processId}:state|| device- ${data?.data?.deviceId}:ws and ${logsTableData?.data?.deviceId}:state`)
              console.log(
                "setting data",
                data?.data?.deviceId,
                stateData?.data?.deviceId,
                i
              );
              setTableData([{ ...data?.data }, ...existingdata]);
            }
          }
        } else {
          if (data?.data?.data) {
            if (
              stateData?.data?.processId === data?.data?.processId &&
              stateData?.data?.deviceId === data?.data?.deviceId
            ) {
              setTableData([
                ...tableData,
                { ...data?.data?.data?.data, qty: 1 },
              ]);
              const x = tableData?.find(
                (y: any) => y?.upc === data?.data?.data?.data?.upc
              );
              if (x) {
                setTableData(
                  tableData?.map((y: any) => {
                    if (x.upc === y.upc) {
                      return { ...y, qty: y.qty + 1 };
                    } else {
                      return y;
                    }
                  })
                );
              }
            }
          } else if (!data?.data?.data) {
            return null;
          }
        }
      });

  useEffect(()=>{
    if(stopbutton === ""){
      setTableData([])
    }else(
      setTableData([...tableData])
    )
  },[])


useEffect(()=>{console.log("CHANGES", logsTableData)},[logsTableData])
 

  const getTableColumns = (processName: any) => {
    let renderColumns :any[] = []

    switch(processName){
      case ProcessName.CHECK_STOCK:
        renderColumns =[
          {
            title: "SCAN TIMESTAMP",
            dataIndex: "scanTime",
            className: "noPadCell",
            width: "20%",
            render: (field: any, row: any) => {
              return (
                <div
                  className="d-flex align-items-center"
                >
                  <div className="pl-10 tableData">{moment(field ? field : row?.createdAt).format("DD-MMM-YYYY hh:mm:ss A")}</div>
                </div>
              );
            },
          },
          {
            title: "QTY EXPECTED",
            dataIndex: "expectedTags",
            className: "centerTable",
            width: "15%",
            render: (tags: any, row: any) => {
              return (
                <div>
                  <span className="tableData">{tags?.length}</span>
                </div>
              );
            },
          },
          {
            title: "QTY RECEIVED",
            dataIndex: "receivedTags",
            className: "centerTable",
            width: "15%",
            render: (tags: any, row: any) => {
              return (
                <div>{tags?.length}</div>
              );
            },
          },
          {
            title: "SCAN STATUS",
            dataIndex: "status",
            className: "centerTable",
            width: "15%",

            render: (status: any, row: any) => {
              return (
                <div>{status? "Passed": "Failed"}</div>
              );
            },
          },
          {
            title: "NOTIFICATION STATUS",
            dataIndex: "notificationStatus",
            width: "20%",
            className: "centerTable",
            render: (notified: any, row: any) => {
              return (
                <div className="notificationdiv"><span><strong>Email:</strong> {notified?.email ? "Sent": "Not Sent"}</span>
                {notified?.webhook != 'notfound' ? <span><strong>Webhook:</strong> {notified?.webhook ? "Sent": "Error occurred in sending notification"}</span> : ""} </div>
              );
            },
          },
        ];
      
        break;
      default:
        renderColumns = [
          {
            title: "UPC",
            dataIndex: "upc",
            render: (field: any, row: any) => {
              return (
                <div
                  className="d-flex align-items-center"
                >
                  <div className="pl-10 tableData">{field}</div>
                </div>
              );
            },
          },
          {
            title: "PRODUCT NAME",
            dataIndex: "description",
            render: (_: any, row: any) => {
              return (
                <div>
                  <span className="tableData">{row?.description}</span>
                </div>
              );
            },
          },
          {
            title: "QTY",
            dataIndex: "qty",
            render: (createdAt: any, row: any) => {
              return (
                <div>{row?.qty}</div>
              );
            },
          },
        ];
    }
    return renderColumns
  }
  const columns = [
    {
      title: "UPC",
      dataIndex: "upc",
      render: (field: any, row: any) => {
        return (
          <div
            className="d-flex align-items-center"
          >
            <div className="pl-10 tableData">{field}</div>
          </div>
        );
      },
    },
    {
      title: "PRODUCT NAME",
      dataIndex: "description",
      render: (_: any, row: any) => {
        return (
          <div>
            <span className="tableData">{row?.description}</span>
          </div>
        );
      },
    },
    {
      title: "QTY",
      dataIndex: "qty",
      render: (createdAt: any, row: any) => {
        return (
          <div>{row?.qty}</div>
        );
      },
    },
  ];

  return (
    <>
      <div className="card-body activityTable1">
        <UITable
          columns={getTableColumns(processData?.processName)}
          data={tableData}
          pagination={false}
          scroll={{ x: 800, y: 500 }}
          className="activitytable"
        />
      </div>
    </>
  )
}