import { apiSlice } from "./apiSlice"
import { apiRoutes } from "../../config/api"

export const processService = apiSlice.injectEndpoints({
    endpoints: builder => ({
          processTagData : builder.query({
            query: (queryParams:any) => {
              console.log(queryParams,">>>>>> params")
              return {
                url: `${apiRoutes.TAG_DATA}/${queryParams?.processId}/${queryParams?.deviceId}/${queryParams?.userId}`,
                method: "GET",
              };
            },
          }),
    })
})

export const {
            useProcessTagDataQuery 
} = processService