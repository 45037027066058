/* eslint react-hooks/exhaustive-deps: 0 */

import { Card, Col, message, Row, TablePaginationConfig } from "antd";
import { useEffect, useState } from "react";
import { deleteDarkIcon } from "../../../assets/icons";
import { ModalButton, ModalPrimaryText, ModalType } from "../../../config/enum";
import { UIConfirmModal } from "../../common/confirmModal";
import { UIDropdown } from "../../common/dropdown";
import * as util from "util";
import { UITable } from "../../common/table"
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { page, rolesOrder } from "../../../config/constants";
import { removeCurrentStep, removeProcessState, setIsPaused, setProcessState, setSyncedData, setTableData } from "../../../redux/features/app/appSlice";
import { useGetAllActiveProcessQuery, useGetAllPreviousPocessQuery, useStopActiveProcessMutation } from "../../../redux/services/processSlice";
import { useDispatch, useSelector } from "react-redux";
import { authStateSelector } from "../../../redux/features/auth/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { Page } from "../../../routes/config";
import UITooltip from "../../common/tooltip";
import { AuditOutlined, EllipsisOutlined } from "@ant-design/icons";
import moment from "moment";
import { getFilterInfo, setFilterState } from "../../../redux/features/filter/filterSlice";
import { UIAvatar } from "../../common/avatar";
import { UICalendar } from "../../common/uiCalendar";
import { useAuditlogsQuery } from "../../../redux/services/auditSlice";

export const PreviousProcess = (props:any) => {
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const filterState = useSelector(getFilterInfo);
  const currentURL = window.location.href;
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: filterState?.page &&  currentURL == filterState?.url ?  filterState?.page : page?.current,
    pageSize: filterState.limit && currentURL == filterState?.url ? filterState.limit : page?.pageSize,
    showSizeChanger: true,
  });
  const {tenantId, user} = useSelector(authStateSelector)
  const [sort, setSort] = useState<any>({
    ...((currentURL == filterState?.url && filterState?.sortBy) && {sortBy: filterState?.sortBy}),
    ...((currentURL == filterState?.url && filterState?.sortOrder) && {sortOrder: filterState?.sortOrder}),
  });

  
  const navigate = useNavigate()

  const params = {
    tenantId: tenantId
  };
  const [tableData, setTableData] = useState([])
  const auditLogs = useGetAllPreviousPocessQuery(params)
  
  useEffect(()=>{
    if(auditLogs?.isSuccess){
      setTableData(auditLogs?.data?.result)
    }
  },[auditLogs?.isSuccess])
  const dispatch = useDispatch()

  const onRowClick = (row:any) => {

  }

  const dropdown = (row:any) => {
        return (
          <UIDropdown
        data-testid="delete"
        items={[
          {
            label: "View Audit Logs",
            key: "2",
            icon: <AuditOutlined />,
            onClick: () => navigate('/auditlogs', {state:row}),
          }
        ]}
        placement="bottom"
      >
          < EllipsisOutlined className="ellipses"/>
      </UIDropdown>
        )
      }

  const columns = [
    {
      title: "Process Name",
      dataIndex: "processName",
      // sorter: true,
      key:"processName",
      className:"activeProcessTable",
      ellipsis: true,
      width: "30%",
      render: (field: any, row: any) => {
        return (
          <div
            className="d-flex align-items-center"
            onClick={() => onRowClick(row)}
          >
            <UITooltip title={row?.processName} placement="top">
            <div className="pl-10 tableData slice" key="processName">{field}</div>
            </UITooltip>
          </div>
        );
      },
    },
    {
      title: "Device Name",
      dataIndex: "deviceName",
      className:"activeProcessTable",
      key:"deviceName",
      width: "30%",
      ellipsis: true,
      // sorter: true,
      render: (field: any, row: any) => {
        return (
          <div  onClick={() => onRowClick(row)}>
            <UITooltip title={row?.deviceName} placement="top">
            <div className="tableData slice" key="deviceName">{field}</div>
            </UITooltip>
          </div>
        );
      },
    },
    {
      title: "Site",
      key:"siteName",
      dataIndex: "siteName",
      width: "22%",
      className:"activeProcessTable",
      ellipsis: true,
      render: (field: any, row: any) => {
        return (
          <div onClick={() => onRowClick(row)}>
            <UITooltip title={row?.siteName} placement="top">
            <div className="tableData slice" key="siteName">{field}</div>
            </UITooltip>
          </div>
        );
      },
    },
    {
      title: "Zone",
      dataIndex: "zoneName",
      key:"zoneName",
      width: "22%",
      className:"activeProcessTable",
      ellipsis: true,
      render: (field: any, row: any) => {
        return (
          <div onClick={() => onRowClick(row)}>
            <UITooltip title={row?.zoneName} placement="top">
            <div className="tableData slice" key="zoneName">{field}</div>
            </UITooltip>
          </div>
        );
      },
    },
    {
      title: "USERNAME",
      dataIndex: "userName",
      width: "25%",
      key:"userName",
      className:"activeProcessTable",
      ellipsis: true,
      render: (field: any, row: any) => {
        return (
          <div onClick={() => onRowClick(row)}>
            <UITooltip title={row?.userName} placement="top">
            <div  className="slice" key="userName">{field}</div>
            </UITooltip>
          </div>
        );
      },
    },
    {
      title: "Tenant",
      dataIndex: "tenantName",
      className:"activeProcessTable",
      key:"tenantName",
      ellipsis: true,
      width: "20%",
      render: (field: any, row: any) => {
        return (
          <div
            className="d-flex align-items-center"
            onClick={() => onRowClick(row)}
          >
            <UITooltip title={row?.tenantName} placement="top">
            <div className="pl-10 tableData" key="tenantName">{field}</div>
            </UITooltip>
          </div>
        );
      },
    },
    {
        title: "Timestamp",
        dataIndex: "updatedAt",
        width: "30%",
        key:"lastSyncTime",
        className:"activeProcessTable",
        ellipsis: true,
        render: (field: any, row: any) => {
          return (
            <div onClick={() => onRowClick(row)}>
              <UITooltip title={moment(field).format("DD MMM YYYY hh:mm A")} placement="top">
                <div className="tableData slice" key="lastSyncTime">{moment(field).format("DD MMM YYYY hh:mm A")}</div>
              </UITooltip>
            </div>
          );
        }
      },
    {
      dataIndex: "login",
      width: "15%",
      key:"login",
      className:"activeProcessTable",
      fixed:"right",   
      render: (login: any, row: any) => {
        return (
          dropdown(row)
      )},
    },
  ];

  interface DataType {
    name: {
      first: string;
      last: string;
    };
    description: string;
    lastUpdated: string;
    createdAt: string;
  }
  interface Params {
    pagination?: TablePaginationConfig;
    sorter?: SorterResult<any> | SorterResult<any>[];
    total?: number;
    sortField?: string;
    sortOrder?: string;
  }

  useEffect(() => {
    setPagination({
      ...pagination,
      locale: { items_per_page: "" },
      total,
    });
  }, [total]);

  const fetchData = (params: Params = {}) => {
    setLoading(true);
    // getActiveProcess?.refetch()
    setSort({
      sortBy: params.sortField,
      sortOrder:
        params.sortOrder === "ascend"
          ? "1"
          : params.sortOrder === "descend"
          ? "-1"
          : undefined,
    });
    setPagination({
      ...params.pagination,
      locale: { items_per_page: "" },
      total,
    });
  };

  const handleTableChange = (
    newPagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<DataType>
  ) => {
    fetchData({
      sortField: sorter.field as string,
      sortOrder: sorter.order as string,
      pagination: newPagination,
      ...filters,
    });
  };

  const [dateParams, setDateParams] = useState();
  const [date, setDate] = useState<any>();

  const handleChange = (e: any, x: any) => {
    x && x?.length > 0
      ? setDate({
          to: moment(x[1])?.format("YYYY-MM-DD"),
          from: moment(x[0])?.format("YYYY-MM-DD"),
        })
      : setDate(x);
    setDateParams(e);
  };

    return (
      <>
      <section className="title-section">
        <Card className="uicard detailed-top-card">
          <Row justify="space-between">
            <Col span={18}>
              <Row gutter={5}>
                <Col span={24} className="slice">
                  <div className="title titleTenant slice tenant-subheading">
                    Audit Logs History (Inactive Process)
                    </div>
                </Col>
                <Col span={24} className="colPaddingdetail">
              
                </Col>
              </Row>
            </Col>
            <Col>
            </Col>
          </Row>
        </Card>
      </section>
        <section className="listing-section">
        <Card className="uicard table uiinner-card">
          <div className="card-body">
            <UITable
              columns={columns}
              data={tableData}
              className="tableAudit"
              rowKey="tenant"
              pagination={pagination}
            //   loading={loading}
              handleTableChange={handleTableChange}
              scroll={{ x: 1000, y: 500 }}
              rowSelection={onRowClick}
            />
          </div>
        </Card>
      </section>
      </>
    )
}