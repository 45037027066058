import { Layout } from "antd";
import UISidebar from "../common/sidebar";
import UIHeader from "../common/header";
import { Outlet } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentAppState } from "../../redux/features/app/appSlice";
const { Content } = Layout;
const UILayout = () => {
  const [collapsed, setCollapsed] = useState(false);

  const {showSideBar} = useSelector(selectCurrentAppState)

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <UIHeader setCollapsed={setCollapsed} collapsed={collapsed} />
      <Layout className="site-layout" style={{ marginTop: "60px" }}>
        {showSideBar ? 
        <UISidebar setCollapsed={setCollapsed} collapsed={collapsed} /> : null}

        <Content
          style={{
            margin: "0 0px",
            marginLeft: showSideBar ? collapsed ? 80: 250: 0
          }}
        >

          <div
            className="site-layout-background" 
            style={{
              padding: 24,
              // height:'100%'
            }}
          >


            <Outlet />

          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default UILayout;
