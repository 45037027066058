import { config } from './index'
import { BaseUrls } from '../types';

const baseUrls: BaseUrls = {
    local: "http://localhost:8080",
    dev: "https://devcloudstation.lifecycles.io",
    qa: "https://qacloudstation.lifecycles.io",
    demo: "https://demo.lifecycles.io",
    prod: "https://devicemanager.lifecycles.io",
}

export const baseURL = baseUrls[config.ENV];

export enum apiRoutes {
    LOGIN = '/api/v1/login',
    GETALLPROCESS = '/api/v1/station/processList',
    REFRESH_TOKEN = '/api/v1/refresh',
    GETALLSITES = '/api/v1/site',
    GETALLSITEBYUSER = '/api/v1/userSites',
    GETALLDEVICEBYSITE = '/api/v1/deviceSite',
    PLAY_EXECUTEPROCESS = '/api/v1/process/execute',
    CANCEL_SITEBUTTON = 'api/v1/process/stop',
    CREATE_STATE = '/api/v1/station/processStates',
    USER_DEVICE = '/api/v1/userDevice',
    START_PROCESS = '/api/v1/process/start',
    DEVICE_STATUS = '/api/v1/deviceStatus',
    DEVICE_STATUS_BY_MAC = '/api/v1/getdeviceStatus',
    RESUME_PROCESS = '/api/v1/process/resume',
    PAUSE_PROCESS = '/api/v1/process/pauseState',
    TAG_DATA = '/api/v1/tag',
    GET_AUTH = '/api/v1/getAuthenticated',
    AUDITS='/api/v1/audits',
    PREVIOUS_PROCESS='/api/v1/states/activeidle'
}
