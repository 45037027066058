/* eslint react-hooks/exhaustive-deps: 0 */

import { Card, Col, message, Row, Spin, TablePaginationConfig } from "antd";
import { useEffect, useState } from "react";
import { deleteDarkIcon } from "../../../assets/icons";
import { ModalButton, ModalPrimaryText, ModalType } from "../../../config/enum";
import { UIConfirmModal } from "../../common/confirmModal";
import { UIDropdown } from "../../common/dropdown";
import * as util from "util";
import { UITable } from "../../common/table"
import './style.css'
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { page, rolesOrder } from "../../../config/constants";
import { removeCurrentStep, removeProcessState, setIsPaused, setProcessState, setSyncedData, setTableData } from "../../../redux/features/app/appSlice";
import { useGetAllActiveProcessQuery, useStopActiveProcessMutation } from "../../../redux/services/processSlice";
import { useDispatch, useSelector } from "react-redux";
import { authStateSelector } from "../../../redux/features/auth/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { Page } from "../../../routes/config";
import UITooltip from "../../common/tooltip";
import { EllipsisOutlined } from "@ant-design/icons";
import moment from "moment";
import { getFilterInfo, setFilterState } from "../../../redux/features/filter/filterSlice";
import { UIAvatar } from "../../common/avatar";
import { data } from "./data";
import { UICalendar } from "../../common/uiCalendar";
import { useAuditlogsQuery } from "../../../redux/services/auditSlice";

// Define types for the audit log query parameters
interface AuditParams {
  processId?: string;
  deviceId?: string;
  page: number;
  limit: number;
  type: string;
  tenantId: string;
  sortBy?: string;
  sortOrder?: string;
  from?: any;
  to?: any
}

// Define types for the row data
interface RowData {
  timestamp?: string;
  eventName?: string;
  userName?: string;
  message?: string;
  detail?: string[];
  deviceName?: string;
  deviceStatus?: string;
  [key: string]: any;
}

// Define the shape of the component's state
interface State {
  processId?: string;
  deviceId?: string;
  processName?: string;
  deviceName?: string;
  siteName?: string;
  zoneName?: string;
}

// Define props (if any)
interface Props {}

export const AuditLogs: React.FC<Props> = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 50,
    total: 100,
    showSizeChanger: true,
  });
  const [sort, setSort] = useState<{ sortBy?: string; sortOrder?: string }>({});
  const [date, setDate] = useState<any>()
  const [dateParams, setDateParams] = useState<any>()
  const filterState = useSelector(getFilterInfo);
  const { tenantId } = useSelector(authStateSelector);
  const location = useLocation();
  const state: any = location.state || {};

  const auditParams: AuditParams = {
    processId: state.processId,
    deviceId: state.deviceId,
    page: pagination.current || 1,
    limit: pagination.pageSize || 10,
    type: 'ActiveProcess',
    tenantId,
    to: date?.to,
    from: date?.from,
    ...sort,
  };

  const auditLogs = useAuditlogsQuery(auditParams,{refetchOnMountOrArgChange:true});

  const fetchData = (params: any = {}) => {
    setLoading(true);
    // auditLogs?.refetch()
    setSort({
      sortBy: params.sortField,
      sortOrder:
        params.sortOrder === "ascend"
          ? "1"
          : params.sortOrder === "descend"
            ? "-1"
            : undefined,
    });
    setPagination({
      ...params.pagination,
      locale: { items_per_page: "" },
      total :100,
    });

  };

  useEffect(() => {
    if (auditLogs?.isSuccess && !auditLogs?.isLoading) {
      setTableData(auditLogs.data.result);
      setLoading(false);
    }
  }, [auditLogs?.isSuccess, auditLogs?.isLoading]);


  const handleTableChange = (
    newPagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<RowData>
  ) => {
    // Update pagination and sorting
    // const newPaginationState = {
    //   ...newPagination,
    //   locale: { items_per_page: '' },
    //   total: pagination.total, // Keep the static total count
    // };
    
    // setPagination(newPaginationState);

    // // Handle sorting
    // setSort({
    //   sortBy: sorter.field as string,
    //   sortOrder: sorter.order as string,
    // });
    fetchData({
      sortField: sorter.field as string,
      sortOrder: sorter.order as string,
      pagination: newPagination,
      ...filters,
    })
  }

  console.log("PAGINATON, table data", pagination, tableData)


  const columns = [
    {
      title: 'Timestamp',
      dataIndex: '',
      className: 'activeProcessTable',
      key: 'timestamp',
      sorter: true,
      ellipsis: true,
      width: '30%',
      render: (text: any, row: RowData) => (
        <div className="d-flex align-items-center">
          <UITooltip title={moment(row?.createdAt).format("DD MMM YYYY hh:mm A")} placement="top">
            <div className="pl-10 tableData">
            {moment(row?.createdAt).format("DD MMM YYYY hh:mm A")}
            </div>
          </UITooltip>
        </div>
      ),
    },
    {
      title: 'Event',
      dataIndex: 'eventName',
      key: 'eventName',
      className: 'activeProcessTable',
      ellipsis: true,
      width: '30%',
      render: (text: string, row: RowData) => (
        <div className="d-flex align-items-center">
          <UITooltip title={row.processName} placement="top">
            <div className="pl-10 tableData slice">{text}</div>
          </UITooltip>
        </div>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'message',
      key: 'message',
      className: 'activeProcessTable',
      width: '30%',
      ellipsis: true,
      render: (text: string, row: RowData) => (
        <div>
          <UITooltip title={row.deviceStatus} placement="top">
            <div className="tableData">{text}</div>
            <div className="tableData">
              <ul>
                {row.detail && row.detail.map((x, i) => (
                  <li key={i}>{x}</li>
                ))}
              </ul>
            </div>
          </UITooltip>
        </div>
      ),
    },
    {
      title: 'Username',
      dataIndex: 'userName',
      key: 'userName',
      className: 'activeProcessTable',
      width: '30%',
      ellipsis: true,
      render: (text: string, row: RowData) => (
        <div>
          <UITooltip title={row.deviceName} placement="top">
            <div className="tableData slice">{text}</div>
          </UITooltip>
        </div>
      ),
    },
  ];

  const handleChange = (e: any, x: any) => {
    x && x?.length > 0
      ? setDate({
          to: moment(x[1])?.format("YYYY-MM-DD"),
          from: moment(x[0])?.format("YYYY-MM-DD"),
        })
      : setDate(x);
    setDateParams(e);
  };

  return (
    <Spin spinning={auditLogs?.isLoading || auditLogs?.isFetching}>
      <section className="title-section">
        <Card className="uicard detailed-top-card">
          <Row justify="space-between">
            <Col span={18}>
              <Row gutter={5}>
                <Col span={24} className="slice">
                  <span className="title titleTenant slice tenant-subheading auditLogs">
                    Audit logs:</span>
                    <span><b>Process:</b> {state.processName}  |  <b>Device:</b> {state.deviceName}  |  <b>Site:</b> {state.siteName}  |  <b>Zone:</b> {state.zoneName}
                      </span>
                </Col>
              </Row>
            </Col>
            <Col span={4} className="calendercsscol">
                <div className="identityCalendar">
                  <UICalendar
                    params={dateParams}
                    setParams={setDateParams}
                    handleChange={handleChange}
                  />
                </div>
              </Col>
          </Row>
        </Card>
      </section>
      <section className="listing-section">
        <Card className="uicard table uiinner-card">
          <div className="card-body">
            <UITable
              columns={columns}
              data={auditLogs?.data?.result}
              className="tableAudit"
              rowKey="id" // Assuming each row has a unique 'id'
              pagination={pagination}
              loading={loading}
              handleTableChange={handleTableChange}
              scroll={{ x: 1000, y: 600 }}
            />
          </div>
        </Card>
      </section>
    </Spin>
  );
};
